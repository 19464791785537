// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-account-tsx": () => import("./../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cart-tsx": () => import("./../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-quote-tsx": () => import("./../src/pages/quote.tsx" /* webpackChunkName: "component---src-pages-quote-tsx" */),
  "component---src-templates-course-tsx": () => import("./../src/templates/course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */),
  "component---src-templates-lesson-tsx": () => import("./../src/templates/lesson.tsx" /* webpackChunkName: "component---src-templates-lesson-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-service-tsx": () => import("./../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

